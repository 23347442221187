<template>
  <div>
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="1000px"
      transition="slide-y-transition"
    >
      <v-card class="dialog-card-border">
        <v-card-title class="text-h6 font-weight-light">
          {{ $t('Residence Data') }} [{{ data.full_name }}]
          <v-spacer />
          <v-btn
            icon
            @click="closeDialog()"
          >
            <v-icon class="mdil-36px mdil-rotate-45">
              mdil-plus
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-simple-table>
                <template #default>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>{{ $t('Residence Date') }}</th>
                      <th>{{ $t('Expire Date') }}</th>
                      <th>{{ $t('Profile picture') }}</th>
                      <th>{{ $t('Residence picture') }}</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr
                      v-for="(row, index) in data.details"
                      :key="index"
                    >
                      <td>{{ index + 1 }}</td>
                      <td>{{ row.residence_date }}</td>
                      <td>{{ row.expire_date }}</td>
                      <td class="py-1">
                        <a
                          :href="`${$_file_path()}${row.profile_picture}?token=${$_auth_token()}`"
                          target="_blank"
                        >
                          <div style="width: 50px; height: 50px">
                            <img
                              :src="`${$_file_path()}${row.profile_picture}?token=${$_auth_token()}`"
                              style="width: 100%; height: 100%; object-fit: cover"
                              class="rounded"
                              alt=""
                            >
                          </div>
                        </a>
                      </td>
                      <td class="py-1">
                        <a
                          :href="`${$_file_path()}${row.residence_picture}?token=${$_auth_token()}`"
                          target="_blank"
                        >
                          <div style="width: 50px; height: 50px">
                            <img
                              :src="`${$_file_path()}${row.residence_picture}?token=${$_auth_token()}`"
                              style="width: 100%; height: 100%; object-fit: cover"
                              class="rounded"
                              alt=""
                            >
                          </div>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider />

        <v-card-actions class="py-3">
          <v-spacer />

          <v-btn
            depressed
            outlined
            small
            @click="closeDialog()"
          >
            {{ $t('Close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    showDialog: { type: Boolean, default: false },
    id: { type: Number, required: true },
  },

  data() {
    return {
      data: {
        full_name: null,
        nationality: null,
        details: []
      },
    }
  },

  watch: {
    showDialog(val) {
      if (val) {
        this.getData()
      }
    },
  },

  methods: {
    getData() {
      axios.get(`residence/${this.id}/client/residences/retrieve/`).then(res => {
        this.data = res.data
        this.fullName = this.data.full_name
      })
    },

    closeDialog() {
      this.$emit('close')
    },
  },
}
</script>
